.title {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  color: #000;
}

.paper {
  max-width: none !important;
  background: #f8fafb;
}
