.boxfirst {
  position: absolute;
  width: 60px;
  height: 40px;
  border: solid 2px #0a238d;
  background-color: #0f2db8;
  border-radius: 4px;
  animation-name: first-box;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-iteration-count: infinite;
  left: 0;
  top: 0;
}

@keyframes first-box {
  0% {
    left: 0;
    top: 0;
  }
  25% {
    left: calc(95px - 60px);
    top: 0;
  }
  50% {
    left: calc(95px - 60px);
    top: calc(85px - 40px);
  }
  75% {
    left: 0px;
    top: calc(85px - 40px);
  }
  100% {
    left: 0px;
    top: 0px;
  }
}

.boxsecond {
  position: absolute;
  width: 30px;
  height: 40px;
  border: solid 2px #4a7df7;
  background-color: #8fabed;
  border-radius: 4px;
  animation-name: second-box;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-iteration-count: infinite;
  left: calc(95px - 30px);
  top: 0;
}

@keyframes second-box {
  0% {
    left: calc(95px - 30px);
    top: 0;
  }
  25% {
    left: calc(95px - 30px);
    top: calc(85px - 40px);
  }
  50% {
    left: 0px;
    top: calc(85px - 40px);
  }
  75% {
    left: 0px;
    top: 0px;
  }
  100% {
    left: calc(95px - 30px);
    top: 0;
  }
}

.boxthird {
  position: absolute;
  width: 60px;
  height: 40px;
  border: solid 2px #0a238d;
  background-color: #0f2db8;
  border-radius: 4px;
  animation-name: third-box;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-iteration-count: infinite;
  left: calc(95px - 60px);
  top: calc(85px - 40px);
}

@keyframes third-box {
  0% {
    left: calc(95px - 60px);
    top: calc(85px - 40px);
  }
  25% {
    left: 0px;
    top: calc(85px - 40px);
  }
  50% {
    left: 0px;
    top: 0px;
  }
  75% {
    left: calc(95px - 60px);
    top: 0;
  }
  100% {
    left: calc(95px - 60px);
    top: calc(85px - 40px);
  }
}

.boxfour {
  position: absolute;
  width: 30px;
  height: 40px;
  border: solid 2px #4a7df7;
  background-color: #8fabed;
  border-radius: 4px;
  animation-name: four-box;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-iteration-count: infinite;
  left: 0px;
  top: calc(85px - 40px);
}

@keyframes four-box {
  0% {
    left: 0px;
    top: calc(85px - 40px);
  }
  25% {
    left: 0px;
    top: 0px;
  }
  50% {
    left: calc(95px - 30px);
    top: 0;
  }
  75% {
    left: calc(95px - 30px);
    top: calc(85px - 40px);
  }
  100% {
    left: 0px;
    top: calc(85px - 40px);
  }
}
